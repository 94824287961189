import { Dealer }                                       from '../types/dealer';
import { Carrier, CarrierId }                           from '../types/carrier';
import { Translation, TranslationId, TranslationInput } from '../types/translation';
import { Filter }                                       from '../types/filter';
import axios                                            from 'axios';
import environment                                      from '../app.environment';
import { getToken }                                     from '@portlet/services/SessionService';
import gql                                              from 'graphql-tag';
import { setContext }                                   from 'apollo-link-context';
import { InMemoryCache }                                from 'apollo-cache-inmemory';
import { createHttpLink }                               from 'apollo-link-http';
import { ApolloClient }                                 from 'apollo-client';
import { getQueryParams }                               from '../helpers';
import { PageInfoQuery }                                from '../types/pageInfoQuery';

class DataService {

    private static instance: DataService = new DataService();

    /**
     * Constructor
     */
    constructor() {
        if (DataService.instance) {
            throw new Error('Error: Instantiation failed: Use DataService.getInstance() instead of new.');
        }
        DataService.instance = this;
    }

    /**
     * Get Instance
     *
     * @return {DataService}
     */
    public static getInstance(): DataService {
        return DataService.instance;
    }

    private async getToken(): Promise<string> {
        return await getToken();
    }

    public getClient(): any {
        const cache    = new InMemoryCache();
        const httpLink = createHttpLink({
            uri: environment.graphQLUrl
        });
        const authLink = setContext(async (_, {headers}) => {
            const token = await this.getToken();

            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : ''
                }
            };
        });

        return new ApolloClient({
            cache,
            link          : authLink.concat(httpLink),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
                query     : {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                }
            }
        });

    }

    async getCarriers(): Promise<Carrier[]> {
        const data = await this.getClient()
            .query({
                query: gql`{
                    carriers {
                        id
                        name
                    }
                }`
            });
        return data.data.carriers;
    }

    async getDealers(carrierId: CarrierId): Promise<Dealer[]> {
        const data = await this.getClient()
            .query({
                query: gql`{
                      carrier(id: ${carrierId}) {
                            dealers {
                                id
                                name
                            }
                      }
                }`
            });
        return data.data.carrier.dealers;
    }

    async getAllDealers(): Promise<Dealer[]> {
        const data = await this.getClient()
            .query({
                query: gql`{
                            dealers {
                                id
                                name
                            }
                }`
            });
        return data.data.dealers;
    }

    async getTranslations(filter: Filter, page: PageInfoQuery): Promise<Translation[]> {
        const token  = await this.getToken();
        const params = getQueryParams({...filter, ...page});

        const data = await axios.get(`${environment.apiUrl}/v2/common/translation/search?${params}`,
            {
                headers: {Authorization: 'Bearer ' + token}
            }
        );
        return data.data;
    }

    async createTranslation(translation: TranslationInput): Promise<any> {
        const token = await this.getToken();

        return await axios.post(`${environment.apiUrl}/v2/common/translation`, translation, {
            headers: {Authorization: 'Bearer ' + token, 'Access-Control-Expose-Headers': 'Location'},
        });
    }

    async deleteTranslation(id: TranslationId): Promise<any> {
        const token = await this.getToken();

        return await axios.delete(`${environment.apiUrl}/v2/common/translation/${id}`,
            {
                headers: {Authorization: 'Bearer ' + token}
            });
    }

    async updateTranslation(translationId: TranslationId, value: string): Promise<any> {
        const token = await this.getToken();

        return await axios.patch(`${environment.apiUrl}/v2/common/translation`, {
            id: translationId,
            value
        }, {
            headers: {Authorization: 'Bearer ' + token}
        });
    }
}

export default DataService;
